<!--    <div class='Dept'></div>-->
<!--    部门管理-->
<template>
    <div class="Dept" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input placeholder="机构名称" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="营业执照">
                            <el-input placeholder="营业执照名称" v-model="form.deptLicenseName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <el-select placeholder="类型" v-model="form.type">
                                <el-option label="请选择" value="" />
                                <el-option label="总部" value="1" />
                                <el-option label="配送中心" value="2" />
                                <el-option label="门店" value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.system.dept.open')"
                >查询
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.system.dept.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="编码" width="165" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构名称" width="200" prop="name" v-if="showColumn('name')" />
                <el-table-column
                    label="营业执照名称"
                    width="200"
                    prop="deptLicenseName"
                    v-if="showColumn('deptLicenseName')"
                />
                <el-table-column label="类型" width="80" prop="type" v-if="showColumn('type')" key="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | deptType }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="省份"
                    width="120"
                    prop="provinceName"
                    v-if="showColumn('provinceName')"
                    sortable
                />
                <el-table-column label="地市" width="80" prop="cityName" v-if="showColumn('cityName')" sortable />
                <el-table-column
                    label="区县"
                    width="80"
                    prop="districtName"
                    v-if="showColumn('districtName')"
                    sortable
                />
                <el-table-column label="地址" width="240" prop="address" v-if="showColumn('address')" sortable />
                <el-table-column label="创建人" width="100" prop="creator" v-if="showColumn('creator')" sortable />
                <el-table-column
                    label="创建时间"
                    width="150"
                    prop="createTime"
                    v-if="showColumn('createTime')"
                    sortable
                />
                <el-table-column
                    label="到期时间"
                    width="150"
                    prop="effectiveTime"
                    v-if="showColumn('effectiveTime')"
                    sortable
                />
                <el-table-column
                    label="所属机构组"
                    property="deptGroupName"
                    width="100"
                    v-if="showColumn('deptGroupName')"
                ></el-table-column>
                <el-table-column
                    label="操作"
                    min-width="340"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleRowEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleRowDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.system.dept.delete')"
                            >删除
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="handelRowSettings(scope.row)"
                            v-if="hasPrivilege('biz.system.deptPrivilege.query')"
                            >机构设置
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="handelRowRenewal(scope.row)"
                            v-if="hasPrivilege('biz.system.deptPrivilege.renewal')"
                            >服务续费
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="机构权限" :visible.sync="dialogVisible">
            <el-card>
                <el-form>
                    <el-form-item>
                        <el-checkbox-group v-model="setting">
                            <el-checkbox :label="dp.code" v-for="dp in deptPrivileges" :key="dp.code"
                                >{{ dp.description }}
                            </el-checkbox>
                            <br />
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="handleRowSettingOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <DeptRenewalBizDialog ref="deptRenewalBizDialog" @closeDeptRenewalBizDialog="handleQuery" />
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import DeptRenewalBizDialog from 'views/menu/system/component/DeptRenewalBizDialog';

export default {
    name: 'Dept',
    components: { CheckTableShowColumnDialog, DeptRenewalBizDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                deptLicenseName: '',
                type: '',
                page: 1,
                limit: Util.Limit,
            },
            settingCode: '',
            //已选定的部门权限
            setting: [],
            settingBackUp: [],
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            dialogVisible: false,
            renewalVisible: false,
            renewalShop: '',
            renewalCode: null,
            deptCode: null,
            tableData: [],
            deptPrivileges: {},
            url: {
                page: '/system/dept/page',
                disable: '/system/dept/disable',
                privilege: '/system/deptPrivilege',
                bindPrivilege: '/system/deptPrivilege/bindPrivilege',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            UrlUtils.Export(this, '部门', '/system/dept/export', this.form);
        },
        handleRowDelete(code) {
            const _delete = () => {
                return this.$http.delete(this.url.disable, { params: { code: code } });
            };
            UrlUtils._commonDml(this, {
                message: '是否删除该信息,删除后不可恢复,是否确认删除?',
                methods: _delete,
            });
        },
        handleRowEdit(row) {
            Util.nameJump(this, 'menu.system.dept.edit', ['系统管理', '部门管理', '编辑部门'], {
                form: row,
                code: row.code,
                deptGroupName: row.deptGroupName,
            });
        },
        handelRowSettings(row) {
            const _this = this;
            this.settingCode = row.code;

            UrlUtils.QueryRemote(this, this.url.privilege + '/' + row.code, (rst) => {
                _this.deptPrivileges = rst;
                _this.setting = rst.filter((r) => r.checked).map((r) => r.code);
                _this.settingBackUp = JSON.parse(JSON.stringify(_this.setting));
                _this.dialogVisible = true;
            });
        },
        handleRowSettingOk() {
            const _this = this;
            UrlUtils.PostRemote(
                this,
                this.url.bindPrivilege,
                {
                    deptCode: _this.settingCode,
                    checked: _this.setting,
                    unchecked: _this.settingBackUp.filter((r) => !_this.setting.includes(r)),
                },
                {
                    transformRequest: [
                        (target) => {
                            return (
                                'deptCode=' +
                                target.deptCode +
                                '&' +
                                target.checked.map((t) => 'checked=' + t).join('&')
                            );
                        },
                    ],
                },
                () => {
                    _this.$message.success('保存成功');
                    _this.dialogVisible = false;
                }
            );
        },
        handelRowRenewal(row) {
            this.renewalVisible = true;
            const _param = {
                renewalShop: row.name,
                deptCode: row.code,
                renewalCode: null,
            };
            this.$refs.deptRenewalBizDialog.show([_param]);
        },
    },
};
</script>

<style scoped>
.Dept .el-form-item {
    margin-bottom: 0;
}
</style>
